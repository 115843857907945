<template>
  <main id="file-page">
    <PageHeader :title="pageTitle" :items="breadcrumb" />
    <b-row>
      <b-col>
        <b-card>
          <b-spinner
            v-if="this.showSpinner"
            class="text-center p-2"
            label="Spinning"
          ></b-spinner>

          <b-form inline class="d-flex justify-content-center" v-else>
            <b-form-group
              v-for="fKey in filtroKeys"
              :key="fKey.key"
              :label-for="fKey.key"
              label-align-md="right"
              :label="fKey.label"
            >
              <b-form-select
                :id="fKey.key"
                class="m-2 ml-3 mr-3"
                v-model="selectVal[fKey.key]"
                :options="options[fKey.key]"
              >
                <template #first>
                  <option :value="undefined">TODOS</option>
                </template>
              </b-form-select>
            </b-form-group>
            <b-form-group
              v-if="hasAnoVigencia"
              label-for="anoVigencia"
              label-align-md="right"
              label="ANO VIGENTE"
            >
              <b-form-select
                key="ano-vigencia"
                id="anoVigencia"
                class="m-2 ml-3 mr-3"
                v-model="anoSelected"
                :options="fetchYears"
              >
                <template #first>
                  <option :value="undefined">TODOS</option>
                </template>
              </b-form-select>
            </b-form-group>
          </b-form>
        </b-card>
      </b-col>
    </b-row>
    <b-row>
      <b-col>
        <b-card>
          <div>
            <b-table
              responsive
              v-if="filteredItems"
              striped
              hover
              :items="filteredItems"
              :fields="fields"
              class="table"
            >
              <template #cell(downloadFile)="data" v-if="allowDownload">
                <span
                  style="font-size: 25px"
                  class="btn-icons"
                  v-on:click="
                    download(
                      data.item.codigoArquivo,
                      data.item.nomeArquivo,
                      extraiExtensao(data.item.nomeArquivo)
                    )
                  "
                >
                  <file-icon
                    v-bind:fileExtension="extraiExtensao(data.item.nomeArquivo)"
                  ></file-icon>
                </span>
              </template>
            </b-table>
            <b v-if="!filteredItems">Arquivos não encontrados</b>
          </div>
        </b-card>
      </b-col>
    </b-row>
  </main>
</template>

<script>
import dayjs from 'dayjs';
import PageHeader from '@/components/page-header';
import {
  fetchFiles,
  downloadFile,
  applyFormat,
  buildFileList,
  hasKey
} from './filePage.service';
import FileIcon from './fileIcon.vue';

dayjs.locale('pt-br');

export default {
  name: 'filePage',
  components: {
    PageHeader,
    FileIcon
  },
  props: {
    pageTitle: {
      type: String,
      default: ''
    },
    columnList: Array,
    filterList: { type: Array, default: () => [] },
    fileType: String,
    allowDownload: Boolean,
    selectVal: Object
  },
  data() {
    return {
      busy: false,
      dataCorte: null,
      fields: [
        ...this.columnList.filter((cl) => !cl.hidden),
        {
          key: 'downloadFile',
          label: 'Download'
        }
      ],
      fileList: [],
      filtroKeys: this.columnList.filter((i) => i.enableFilter),
      showSpinner: true,
      anoSelected: undefined
    };
  },
  methods: {
    download(codigoArquivo, nomeArquivoCompleto, type) {
      this.downloadArquivo(codigoArquivo, nomeArquivoCompleto, type);
    },
    downloadArquivo(codigoArquivo, nomeArquivoCompleto, type) {
      downloadFile(codigoArquivo, nomeArquivoCompleto, type);
    },
    extraiExtensao: function (nomeArquivo) {
      let nomeArqArr = nomeArquivo.split('.');

      let extensao = nomeArqArr[nomeArqArr.length - 1];

      return extensao;
    },
    hasKey(key) {
      return hasKey(key, this.filterList);
    }
  },
  mounted() {
    fetchFiles(this.dataCorte, this.fileType)
      .then((response) => {
        this.fileList = buildFileList(response, this.columnList);
        this.busy = false;
        this.showSpinner = false;
      })
      .catch((err) => console.log(err));
  },
  computed: {
    breadcrumb: function () {
      return [
        {
          text: 'Arquivos',
          href: '/'
        },
        {
          text: this.pageTitle,
          active: true
        }
      ];
    },
    filteredItems() {
      return this.fileList.filter((item) => {
        let keep = true;

        // This is a basic equality filter. What I did in the actual code was to have an object with filter functions for each key. If a key was missing, it defaulted to straight equality.
        this.filtroKeys.forEach((fKey) => {
          keep =
            keep &&
            (this.selectVal[fKey.key] === undefined ||
              item[fKey.key] === this.selectVal[fKey.key]);
        });

        // anoVigencia
        let filters = this.filterList;
        if (typeof filters !== 'undefined' && filters !== null) {
          filters.forEach((fil) => {
            if (fil.key === 'anoVigencia') {
              keep =
                keep &&
                (this.anoSelected === undefined ||
                  (dayjs(item[fil.columnStart], fil.columnsFormat).year() <=
                    this.anoSelected &&
                    this.anoSelected <=
                      dayjs(item[fil.columnEnd], fil.columnsFormat).year()));
            }
          });
        }

        return keep;
      });
    },
    hasAnoVigencia() {
      let hasAnoVigenciaK = hasKey('anoVigencia', this.filterList);
      return hasAnoVigenciaK;
    },
    fetchYears() {
      let setYears = new Set();
      this.fileList.map((item) => {
        setYears.add(dayjs(item.vigenciaIni, 'YYYY-MM-DD').year());
        setYears.add(dayjs(item.vigenciaFim, 'YYYY-MM-DD').year());
      });

      let yearsIncomplete = Array.from(setYears).sort();

      let yearsComplete = [];

      for (
        let i = yearsIncomplete[0];
        i <= yearsIncomplete[yearsIncomplete.length - 1];
        i++
      ) {
        yearsComplete.push(i);
      }

      return yearsComplete;
    },
    options() {
      // This could be simplified if the select was it's own component.
      const options = {};

      this.filtroKeys.forEach((fKey) => {
        const vals = this.fileList.map((item) => {
          let optText = applyFormat(fKey, item);
          return {
            value: item[fKey.key],
            text: optText
          };
        });

        // transforma vals em um Set
        let seen = new Set();
        let opts = vals.filter((v) => {
          const keep = !seen.has(v.value);

          if (keep) {
            seen.add(v.value);
          }
          return keep;
        });

        const sortOrder = fKey.sortingOrder;
        if (sortOrder) {
          if (sortOrder === 'asc') {
            opts.sort((a, b) => (a.value > b.value ? 1 : -1));
          } else if (sortOrder === 'desc') {
            opts.sort((a, b) => (a.value < b.value ? 1 : -1));
          } else if (sortOrder === 'month name asc') {
            let months = [
              'janeiro',
              'fevereiro',
              'março',
              'abril',
              'maio',
              'junho',
              'julho',
              'agosto',
              'setembro',
              'outubro',
              'novembro',
              'dezembro'
            ];

            opts.sort((a, b) =>
              months.indexOf(a.value) > months.indexOf(b.value) ? 1 : -1
            );
          }
        }

        if (opts.length > 1) {
          // remove opção null para campos nao preenchidos
          options[fKey.key] = opts;
        }
      });

      return options;
    }
  }
};
</script>

<style lang="scss" scoped>
.btn {
  min-width: 100px !important;
}

.btn-icons {
  background: transparent;
  border: none;
}

.icons {
  color: black;
  size: 50px;
  cursor: pointer;
}
</style>

<template>
  <i :style="icon.style" :class="icon.class"></i>
</template>

<script>
export default {
  name: 'fileIcon',
  props: {
    fileExtension: String
  },
  computed: {
    icon: function () {
      let iconStyle = '';
      let iconClass = '';
      switch (this.fileExtension.toLowerCase()) {
        case 'txt':
          iconStyle = 'color: #387881';
          iconClass = 'far fa-file-alt icons';
          break;
        case 'xlsx':
          iconStyle = 'color: #1e6e42';
          iconClass = 'far fa-file-excel icons';
          break;
        case 'csv':
          iconStyle = 'color: #00703e';
          iconClass = 'fas fa-file-csv icons';
          break;
        case 'pdf':
          iconStyle = 'color: #b50706';
          iconClass = 'fas fa-file-pdf icons';
          break;
        case 'docx':
          iconStyle = 'color: #315395';
          iconClass = 'far fa-file-word icons';
          break;
        case 'zip':
          iconStyle = 'color: #f6a803';
          iconClass = 'far fa-file-archive icons';
          break;
        default:
          iconStyle = 'color: #aaaaaa';
          iconClass = 'fas fa-file icons';
          break;
      }

      let ans = {
        style: iconStyle,
        class: iconClass
      };

      return ans;
    }
  }
};
</script>
